import PropTypes from 'prop-types';
import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useRdmdOpts from '@core/hooks/useRdmdOpts';
import useRoute from '@core/hooks/useRoute';
import { htmlBlock } from '@core/utils/magicBlocks';

import RDMD from '@ui/RDMD';

import classes from './style.module.scss';

const CustomPage = props => {
  const { state: { custompage = {} } = {} } = useRoute(props);
  const { title, htmlmode = false, body: markdown, html, slug } = custompage;
  const bem = useClassy(classes, 'ContentContainer');
  const rdmdOpts = useRdmdOpts();

  return (
    <div className="rm-CustomPage" id="content">
      <div className={bem('grid-container-fluid', custompage.fullscreen && '-fullscreen')} id="content-container">
        <header id="content-head">
          <h1>{title}</h1>
        </header>
        <RDMD key={slug} opts={!htmlmode ? rdmdOpts : {}}>
          {!htmlmode ? markdown : htmlBlock(html)}
        </RDMD>
        <div className="ModalWrapper" id="tutorialmodal-root" />
      </div>
    </div>
  );
};

CustomPage.propTypes = {
  custompage: PropTypes.shape({
    body: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  meta: PropTypes.object,
};

export default CustomPage;
